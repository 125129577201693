<template>
  <f7-sheet class="rateservice-sheet" swipe-to-close backdrop>
    <f7-page-content>
      <div class="content">
        <h1>{{ $t.getTranslation("LBL_RATE_OUR_SERVICE") }}</h1>
        <p>{{ $t.getTranslation("LBL_RATE_OUR_SERVICE_SUB") }}</p>

        <div class="review">
          <div class="rating">
            <h3>{{ $t.getTranslation("LBL_WINE_SELECTION") }}</h3>
            <star-rating :star-size="20" :rating="formData.WineSelection" :show-rating="false" :increment="0.5" :read-only="false" @update:rating="setRatingWineSelection"></star-rating>
          </div>
          <div class="rating">
            <h3>{{ $t.getTranslation("LBL_PLATFORM_FRIENDLINESS") }}</h3>
            <star-rating :star-size="20" :rating="formData.PlatformFriendliness" :show-rating="false" :increment="0.5" :read-only="false" @update:rating="setRatingPlatformFriendliness"></star-rating>
          </div>

          <f7-list form no-hairlines>
            <f7-list-input
              v-model:value="formData.Comment"
              name="Comment"
              :label="$t.getTranslation('LBL_COMMENT')"
              :required="validationRules?.Comment?.required"
              :minlength="validationRules?.Comment?.minimumLength"
              :maxlength="validationRules?.Comment?.maximumLength"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              floating-label
              outline
              type="textarea"
              validate
              clear-button
            >
            </f7-list-input>
            <f7-list-item>
              <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableButton" @click="sendReview">
                {{ $t.getTranslation("LBL_SEND_REVIEW") }}
              </f7-button>
            </f7-list-item>
          </f7-list>
        </div>
      </div>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { defineComponent, reactive, ref, computed, inject, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";
import { useStore } from "@/store";

// import StarRating from "@/components/rating/star-rating.vue";

const StarRating = defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ "@/components/rating/star-rating.vue"));

export default defineComponent({
  name: "OrderRateServiceComponent",
  components: {
    StarRating,
  },
  props: {},
  emits: ["sendReview"],
  setup(props, { emit }) {
    const store = useStore();
    
    const $t = inject("$translation");

    const isButtonProcessing = ref(false);

    const formData = reactive({
      WineSelection: "",
      PlatformFriendliness: "",
      Comment: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const setRatingWineSelection = (rating, type) => {
      formData.WineSelection = rating;
    };

    const setRatingPlatformFriendliness = (rating, type) => {
      formData.PlatformFriendliness = rating;
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const sendReview = async () => {
      let isValid = validate(true);
      if (!(formData.WineSelection >= 1 && formData.WineSelection <= 5)) {
        isValid = false;

        helpers.createNotification({
          type: "error",
          title: "Error!",
          message: `Invalid Wine Selection rating. Value must be between 1 and 5.`,
        });

        return;
      }

      if (!(formData.PlatformFriendliness >= 1 && formData.PlatformFriendliness <= 5)) {
        isValid = false;

        helpers.createNotification({
          type: "error",
          title: "Error!",
          message: `Invalid Platform Friendliness rating. Value must be between 1 and 5.`,
        });

        return;
      }

      if (isValid) {
        isButtonProcessing.value = true;

        let returnData = await post("/platform/review/save", formData);
        if (returnData) {
          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_THANK_YOU"),
            message: $t.getTranslation("LBL_SUCCESSFULLY_SEND_PLATFORM_REVIEW"),
          });

          f7.sheet.get(".rateservice-sheet").close();
        }

        emit("sendReview", formData);
        isButtonProcessing.value = false;
      }
    };

    const showRateServiceSheet = async () => {
      f7.sheet.get(".rateservice-sheet").open();
    };

    return {
      formData,
      validationRules,
      isButtonProcessing,
      isDisableButton,
      setRatingWineSelection,
      setRatingPlatformFriendliness,
      showRateServiceSheet,
      sendReview,
      store
    };
  },
});
</script>

<style scoped>
.rateservice-sheet {
  --f7-sheet-height: 460px;
  --f7-sheet-bg-color: #fff;
  overflow: auto;

  --f7-list-item-padding-horizontal: 0px;
  --f7-list-item-title-font-size: 14px;
  --f7-list-item-title-font-weight: 400;
  --f7-list-border-color: #f7f7f7;
  --f7-list-item-border-color: #f7f7f7;
}
.rateservice-sheet .content {
  padding: 20px 40px;
}
.rateservice-sheet .content h1 {
  font-size: 22px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
}
.rateservice-sheet .review h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0px 10px;
}
.rateservice-sheet .list {
  width: 100%;
}
.rateservice-sheet .list .button {
  width: 100%;
  margin-top: 10px;
}
</style>
